/* Obvious Imports */

import { PaletteMode } from "@mui/material";

/*

Our theme is going to use custom breakpoints and the @mui/material/styles module needs to be augmented to accommodate the new types we are going to define.

*/

declare module "@mui/material/styles" {
	interface BreakpointOverrides {
		xxs: true;
		xs: true;
		xsPlus: true;
		sm: true;
		smPlus: true;
		sm822: true;
		sm860: true;
		sm910: true;
		md: true;
		md1190: true;
		md1220: true;
		lg: true;
		lgPlus: true;
		xl: true;
		xxl: true;
		xxxl: true;
	}
	interface Palette {
		icon: Palette["primary"];
	}
	interface PaletteOptions {
		icon?: PaletteOptions["primary"];
	}
}

/*

Our theme is going to use a custom color palette but we have not defined any new types yet, so there is no need for module augmentation. However, Before beginning frontend development it might be wise to define some custom colors in our palette.

*/
const Styles = {
	light: {
		// palette values for light mode
		primary: {
			main: "#FC8019",
			light: "#FC9947",
			dark: "#B05911",
			contrastText: "#FFFFFF",
		},
		secondary: {
			main: "#FFFFFF",
			light: "#FFFFFF",
			dark: "#CCCCCC",
			contrastText: "#FFFFFF",
		},
		info: {
			main: "#2196F3",
			light: "#64B6F7",
			dark: "#0B79D0",
			contrastText: "#FFFFFF",
		},
		warning: {
			main: "#ED6C02",
			light: "#FFB547",
			dark: "#C77700",
			contrastText: "#FFFFFF",
		},
		success: {
			main: "#1ba672",
			light: "#1ba672",
			dark: "#3B873E",
			contrastText: "#FFFFFF",
		},
		error: {
			main: "#F44336",
			light: "#F88078",
			dark: "#E31B0C",
			contrastText: "#FFFFFF",
		},
		background: {
			paper: "#FFFFFF",
			default: "#FAFAFA",
			highlight: "#FFF5ED",
		},
		text: {
			primary: "#212121",
			secondary: "#666666",
			disabled: "#9E9E9E",
		},
		icon: {
			main: "#212121",
			light: "#75706D",
			dark: "#B05911",
			contrastText: "#FFFFFF",
		},
	},
	dark: {
		// palette values for dark mode
		primary: {
			main: "#FF8A29",
			light: "#FF9C49",
			dark: "#D8721D",
			contrastText: "#E7E7E7",
		},
		secondary: {
			main: "#FFFFFF",
			light: "#FFFFFF",
			dark: "#C4C4C4",
			contrastText: "#E7E7E7",
		},
		info: {
			main: "#29B6F6",
			light: "#4FC3F7",
			dark: "#0288D1",
			contrastText: "#E7E7E7",
		},
		warning: {
			main: "#FFA726",
			light: "#FFA726",
			dark: "#F57C00",
			contrastText: "#E7E7E7",
		},
		success: {
			main: "#1ba672",
			light: "#7fffcf",
			dark: "#388E3C",
			contrastText: "#E7E7E7",
		},
		error: {
			main: "#F44336",
			light: "#F88078",
			dark: "#E31B0C",
			contrastText: "#E7E7E7",
		},
		background: {
			paper: "#424242",
			default: "#303030",
			highlight: "#51473F",
			icon: "#FFFFFF",
		},
		text: {
			primary: "#FFFFFF",
			secondary: "#C6C6C6",
			disabled: "#A0A0A0",
		},
		icon: {
			main: "#FFFFFF",
			light: "#B3AEAB",
			dark: "#B05911",
			contrastText: "#FFFFFF",
		},
	},
};

export type breakpoint =
	| "none"
	| "xxs"
	| "xs"
	| "xsPlus"
	| "sm"
	| "smPlus"
	| "sm822"
	| "sm860"
	| "sm910"
	| "md"
	| "md1190"
	| "md1220"
	| "lg"
	| "lgPlus"
	| "xl"
	| "xxl"
	| "xxxl"
	| "base";
export type paddingBreakpoint = [breakpoint, string];
export type paddingBreakpoints = Map<breakpoint, string>;

export const paddingBreakpoints: paddingBreakpoints = new Map([
	["none", "0rem"],
	["xxs", "0.5rem"],
	["xs", "1rem"],
	["xsPlus", "1.5rem"],
	["sm", "3rem"],
	["smPlus", "3rem"],
	["sm822", "3rem"],
	["sm860", "3rem"],
	["sm910", "3rem"],
	["md", "4rem"],
	["md1190", "4rem"],
	["md1220", "4rem"],
	["lg", "4rem"],
	["lgPlus", "4.26875rem"],
	["xl", "6rem"],
	["xxl", "12rem"],
	["xxxl", "16rem"],
]);

export type DesktopScaleFactors = Map<breakpoint, number>;
export type DesktopScaleFactorsForSpacing = Map<breakpoint, number>;
export const desktopScaleFactors: DesktopScaleFactors = new Map([
	["base", 1],
	["lg", 1],
	["lgPlus", 1.0671875],
	["xl", 1.5],
	["xxl", 2],
	["xxxl", 3],
]);
export const desktopScaleFactorsForSpacing: DesktopScaleFactorsForSpacing = new Map([
	["base", 1],
	["lg", 1],
	["lgPlus", 1],
	["xl", 1.5],
	["xxl", 1.5],
	["xxxl", 2],
]);
export const getDesktopScaleFactor: (breakpoint: breakpoint) => number = (breakpoint: breakpoint) => {
	const scaleFactor: number | undefined = desktopScaleFactors.get(breakpoint);
	if (scaleFactor === undefined) {
		return 1;
	}
	return scaleFactor as number;
};
export const getDesktopScaleFactorForSpacing: (breakpoint: breakpoint) => number = (breakpoint: breakpoint) => {
	const scaleFactorForSpacing: number | undefined = desktopScaleFactorsForSpacing.get(breakpoint);
	if (scaleFactorForSpacing === undefined) {
		return 1;
	}
	return scaleFactorForSpacing as number;
};

export type MUIComponentName = string;
export type MUIComponentSize = { value: number; unit: string };
export type MUIComponentDefaultSize = Map<MUIComponentName, MUIComponentSize>;
export const MUIComponentSizeDefaults: MUIComponentDefaultSize = new Map([
	["InputFieldHeight", { value: 3.5, unit: "rem" }],
	["InputFieldPaddingTop", { value: 16.5, unit: "px" }],
	["InputFieldPaddingBottom", { value: 14, unit: "px" }],
	["InputFieldFontSize", { value: 1, unit: "rem" }],
	["IconSize", { value: 24, unit: "px" }],
	["CheckboxPadding", { value: 9, unit: "px" }],
	["CheckboxLabelHeight", { value: 42, unit: "px" }],
	["ButtonHeight", { value: 40.5, unit: "px" }],
	["ButtonFontSize", { value: 0.875, unit: "rem" }],
]);
export const getMUIComponentDefaultSize: (componentName: MUIComponentName) => MUIComponentSize = (
	componentName: MUIComponentName,
) => {
	const componentSize: MUIComponentSize | undefined = MUIComponentSizeDefaults.get(componentName);
	if (componentSize === undefined) {
		return { value: 1, unit: "rem" };
	}
	return componentSize as MUIComponentSize;
};

const GetDesignTokens = (mode: PaletteMode) => ({
	palette: {
		mode,
		...(mode === "dark" ? Styles.dark : Styles.light),
	},
	typography: {
		fontFamily: '"Poppins", "Roboto", "Helvetica", "Arial", sans-serif',
	},
	breakpoints: {
		values: {
			xxs: 0,
			xs: 280.05,
			xsPlus: 540,
			sm: 600,
			smPlus: 768,
			sm822: 822,
			sm860: 860,
			sm910: 910,
			md: 960,
			md1190: 1190,
			md1220: 1220,
			lg: 1280,
			lgPlus: 1366,
			xl: 1920,
			xxl: 2560,
			xxxl: 3840,
		},
	},
	components: {
		MuiFormLabel: {
			styleOverrides: {
				asterisk: {
					display: "none",
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					borderRadius: "12px",
				},
			},
		},
		MuiDialog: {
			styleOverrides: {
				paper: {
					borderRadius: "16px",
					overflow: "hidden",
				},
			},
		},
	},
});

export default GetDesignTokens;
